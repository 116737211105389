

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.css-10hburv-MuiTypography-root{
  display:"flex"
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 18px; /* Base text size for readability */
  font-weight: bold; /* Makes all text in the table bold */
  border: 2px solid #4CAF50; /* Keeps the border thick and noticeable */
}

th, td {
  padding: 15px; /* Increased padding for more space around text */
  border: 2px solid #ddd; /* Slightly thicker cell borders for distinction */
  text-align: center; /* Centers content in cells */
  font-family: Arial, sans-serif; /* Modern, readable font for clarity */
  vertical-align: middle; /* Ensures content is centered vertically */
}

th {
  background-color: #4CAF50; /* Vibrant header background color for aesthetics */
  color: white; /* Ensures text is readable against the header background */
  font-size: 20px; /* Slightly larger font size for header emphasis */
}

th a {
  color: white !important; /* Ensures links are white and uses !important to override any other link color styles */
  text-decoration: underline; /* Underlines links for clarity */
}

td a {
  color: inherit; /* Makes body cell links inherit the text color of the cell */
  text-decoration: underline; /* Underlines links for clarity */
}

th a:hover, td a:hover {
  color: #f2f2f2; /* Changes link color on hover for interaction, adjust as needed */
}

tbody tr:nth-child(odd) {
  background-color: #f9f9f9; /* Zebra striping for rows to improve readability */
}

tbody tr:hover {
  background-color: #f2f2f2; /* Highlights row on hover for better interaction */
}

/* New CSS rule to target icons specifically */
th i, td i {
  font-size: 36px; /* Makes icons twice as big compared to the base text size of 18px */
}

/* Alternative if icons are within links */
th a i, td a i {
  font-size: 36px; /* Adjust as needed, assuming twice the base font size */
}
